<template>
  <div class="add-expense">
    <div class="expense" style="display: flex; align-items: end">
      <van-field
        v-model="diner_amount"
        type="number"
        label="预计餐费"
        :disabled="!editable"
        required
      />
      <div
        style="
          font-size: 15px;
          width: auto;
          color: #a7a6a6;
          position: absolute;
          top: 15px;
          right: 10px;
        "
      >
        YTD餐费余额：<span style="color: #008bff">{{ budgetRes }}</span
        >{{ isNaN(budgetRes) ? '' : '元' }}
      </div>
      <div
        style="
          font-size: 16px;
          display: flex;
          justify-content: space-between;
          width: 100%;
          padding-bottom: 20px;
          padding-right: 10px;
        "
      >
        <div>元</div>
        <div style="color: #a7a6a6">
          人均<span style="color: #008bff">{{
            totalPeople ? (diner_amount / totalPeople).toFixed(2) : '0.00'
          }}</span
          >元
        </div>
      </div>
    </div>
    <div class="cost_proportion" v-if="showCostProportion">
      <div
        style="
          font-size: 16px;
          color: #646566;
          font-weight: 400;
          text-align: left;
        "
      >
        分产品比例
      </div>
      <van-field
        v-for="(item, index) in costProportion"
        :key="index"
        :label="item.product_name"
        type="number"
        v-model="costProportionSubmit[index].cost_proportion"
        :disabled="!editable"
        style="width: 300px"
      >
        <template #button> % </template>
      </van-field>
    </div>
    <div class="people">
      <van-field type="number" label="用餐人" required />
      <div
        style="
          position: absolute;
          right: 10px;
          top: 10px;
          font-size: 16px;
          color: #a7a6a6;
        "
      >
        共<span style="color: #008bff">{{
          doctors.length + colleagues.length
        }}</span
        >人
      </div>
      <div class="add-doctor">
        <!-- <span style="font-size: 12px">{{ doctors }}</span> -->
        <!-- 添加的医生 -->
        <div style="padding: 0 10px">
          <div>
            <div
              v-for="(item, index) in doctors"
              :key="index"
              class="input-style"
            >
              <div class="input-border">
                <span>{{ item.physician_name }}</span>
                <span class="input-mini-words">{{
                  item.institution_name
                }}</span>
              </div>
              <van-icon
                v-if="editable"
                name="cross"
                color="red"
                class="delete-icon"
                @click="removeDoctor(index)"
              />
            </div>
          </div>
        </div>
        <div style="text-align: left">
          <van-button
            type="info"
            round
            size="small"
            plain
            :disabled="!editable"
            @click="showDoctorSelect = true"
          >
            <van-icon name="add-o" />
            添加医生
          </van-button>
        </div>
        <div style="padding: 0 10px">
          <div style="margin-top: 10px; width: calc(100% - 10px); height: 1px; border-top: 1px solid #eee;"></div>
          <!-- <span style="font-size: 12px;">{{ colleagues }}</span> -->
          <div
            v-for="(item, index) in colleagues"
            :key="index"
            class="input-style"
          >
            <div class="input-border">
              <span>{{ item.user_name }}</span>
              <span class="input-mini-words">{{ item.position }}</span>
            </div>
            <van-icon
              v-if="editable"
              name="cross"
              color="red"
              class="delete-icon"
              @click="removeColleague(index)"
            />
          </div>
        </div>
        <div style="text-align: left">
          <van-button
            type="info"
            round
            size="small"
            plain
            :disabled="!editable"
            @click="showSelectColleague = true"
          >
            <van-icon name="add-o" />
            添加同事
          </van-button>
        </div>
      </div>
    </div>
    <DoctorListSelectAllAndNew
      v-if="showDoctorSelect"
      :multipleMode="true"
      :defaultSelectedList="doctors"
      @close="showDoctorSelect = false"
      @select="handleSelectDoctor"
    />
    <MealCompanionUserSelectField
      v-model="showSelectColleague"
      :listValue="colleagues"
      @ok="handleSelectColleague"
    />
  </div>
</template>

<script>
import MealCompanionUserSelectField from '@/components/form/MealCompanionUserSelectField.vue';
import DoctorListSelectAllAndNew from '@/components/DoctorListSelectAllAndNew.vue';
import {getLocalStorage} from '@/utils';
export default {
  components: {DoctorListSelectAllAndNew, MealCompanionUserSelectField},
  name: 'AddExpense',
  data() {
    return {
      // 在这里定义数据
      diner_amount: 0,
      showDoctorSelect: false,
      showSelectColleague: false,
      doctors: [],
      colleagues: [],
      myColleagues: [],
      myDoctors: [],
      costProportionSubmit: [],
      defaultCallProducts: [],
      editable: true,
    };
  },
  props: [
    'showAddExpense',
    'showCostProportion',
    'costProportion',
    'defDoctors',
    'defColleagues',
    'budgetRes',
    'callProducts',
    'defDinerAmount',
    'defEditable',
  ],
  watch: {
    costProportion(newVal) {
      console.log('cnm---->1', newVal);
      this.costProportionSubmit = newVal.map((item, index) => {
        return {
          product_id: item.product_id,
          product_name: item.product_name,
          cost_proportion:
            Math.floor(100 / newVal.length) +
            (index < 100 % newVal.length ? 1 : 0),
        };
      });
    },
    defDoctors(newVal) {
      this.doctors = newVal;
    },
    defColleagues(newVal) {
      this.colleagues = newVal;
    },
    defDinerAmount(newVal) {
      this.diner_amount = newVal;
    },
    defEditable(newVal) {
      this.editable = newVal;
    },
    callProducts(newVal) {
      if (newVal) {
        this.defaultCallProducts = JSON.parse(newVal);
        this.costProportionSubmit = JSON.parse(newVal);
      }
    },
  },
  computed: {
    totalPeople() {
      return this.doctors.length + this.colleagues.length;
    },
  },
  mounted() {
    this.colleagues = [
      {
        user_id: getLocalStorage('user_id'),
        user_name: getLocalStorage('user_name'),
      },
    ];
  },
  methods: {
    // 在这里定义方法
    handleSelectDoctor(data) {
      this.showDoctorSelect = false;
      this.doctors = data.map((item) => {
        return {
          physician_id: item.physician_id,
          physician_name: item.physician_name,
          department_name: item.department_name,
          institution_id: item.institution_id,
          institution_name: item.institution_name,
          professional_title: item.professional_title,
        };
      });
    },
    handleSelectColleague(data) {
      this.colleagues = this.colleagues.concat(
        data.filter(item => item && !this.colleagues.some(colleague => colleague.user_id === item.user_id))
          .map(item => ({
            user_id: item.user_id,
            user_name: item.user_name
          }))
      );
    },
    removeDoctor(index) {
      this.doctors.splice(index, 1);
    },
    removeColleague(index) {
      let newArr = this.colleagues;
      newArr.splice(index, 1);
      this.colleagues = newArr;
    },
    handleChangeCostProportion(index, value) {
      this.costProportionSubmit[index].cost_proportion = Number(value);
    },
  },
};
</script>

<style lang="scss" scoped>
.add-expense {
  /* 在这里添加样式 */
  background-color: #fff;
  padding-bottom: 20px;
}
.people ::v-deep .van-field__value {
  display: none;
}
.expense {
  position: relative;
}
.expense ::v-deep .van-cell {
  display: block;
}

.expense ::v-deep .van-cell .van-cell__value {
  border: 1px solid #dcdcdc;
  margin: 6px 0px 0px;
  padding: 4px 8px;
  border-radius: 4px;
}
.cost_proportion {
  margin-top: 20px;
  padding: 10px;
}
.cost_proportion ::v-deep .van-cell {
  // display: block;
  display: flex;
  align-items: center;
}
.cost_proportion ::v-deep .van-field__label {
  width: auto;
}
.cost_proportion ::v-deep .van-cell .van-cell__value {
  border: 1px solid #dcdcdc;
  margin: 6px 0px 0px;
  padding: 4px 8px;
  border-radius: 4px;
}
::v-deep .van-checkbox {
  margin-bottom: 10px;
}

.checkbox ::v-deep .van-cell .van-cell__value {
  border: none;
  margin: 0 -8px -18px;
}

::v-deep .van-checkbox-group {
  width: 100%;
}
.people {
  position: relative;
}
.add-doctor ::v-deep .van-button--info {
  border: none;
}
.input-style {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  margin-top: 15px;
}
.input-border {
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  padding: 10px 10px;
  width: 80%;
  text-align: left;
}
.input-mini-words {
  font-size: 12px;
  color: #a7a6a6;
  margin-left: 10px;
}
</style>
